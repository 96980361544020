export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : (hour <= 11 ? '上午好' : (hour <= 13 ? '中午好' : (hour < 20 ? '下午好' : '晚上好')))
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor((Math.random() * arr.length))
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function menuModify (resource) {
  const menu = {
    path: resource.urls,
    meta: {
      title: resource.name,
      icon: resource.iconCode || 'none'
    },
    children: null,
    hideChildrenInMenu: false,
    level: 1,
    key: resource.id + '',
    parentKeys: resource.parentIds ? resource.parentIds.split('/').filter(x => x) : []
  }
  // 根据父节点 判断当前栏目属于几级  /pid/ 2级  /pid/pid/ 3级
  if (resource.parentIds) {
    const l = resource.parentIds.split('/').length
    if (l > 1) {
      menu.hideChildrenInMenu = true
      menu.level = l - 1
    }
  }
  // 存在 children
  if (resource.hasChildren) {
    const children = []
    for (let i = 0, length = resource.children.length; i < length; i++) {
      const childMenu = menuModify(resource.children[i])
      childMenu.parent = menu
      children.push(childMenu)
    }
    menu.children = children
  }
  return menu
}

/**
 * 查找 path and level menu
 * @param menus
 * @param path
 * @param level
 */
export function findMenu (menus, path, level) {
  for (let i = 0; i < menus.length; i++) {
    const menu = menus[i]
    if ((menu.level === level || !level) && menu.path === path) {
      return menu
    }
    // 有孩子节点
    if (menu.children) {
      const childMenu = findMenu(menu.children, path, level)
      if (childMenu) {
        return childMenu
      }
    }
  }
}