import serverUrl from './service.info'

const apiVersion = '/api/v1'

const role = {
  search: {
    path: serverUrl + apiVersion + '/role/search',
    method: 'GET',
    params: {
      number: 'String',
      size: 'String',
      direction: 'String',
      order: 'String',
      q: 'String',
    },
  },
  create: {
    path: serverUrl + apiVersion + '/role/create',
    method: 'POST',
  },
  update: {
    path: serverUrl + apiVersion + '/role/{id}/update',
    method: 'PUT',
    params: {
      id: 'String',
    },
  },
  dataUpdate: {
    path: serverUrl + apiVersion + '/role/dataAuth/update',
    method: 'POST',
  },
  selectTag: {
    path: serverUrl + apiVersion + '/role/select/{tag}',
    method: 'GET',
    params: {
      tag: 'String',
    },
  },
  delete: {
    path: serverUrl + apiVersion + '/role/{id}/del',
    method: 'DELETE',
    params: {
      id: 'String',
    },
  },
  deleteMore: {
    path: serverUrl + apiVersion + '/role/delMore',
    method: 'DELETE',
    params: {
      idList: 'SearchPoint.vue',
    },
  },
  view: {
    path: serverUrl + apiVersion + '/role/{id}/view',
    method: 'GET',
    params: {
      id: 'String',
    },
  },
  select: {
    path: serverUrl + apiVersion + '/role/select',
    method: 'GET',
  },
  selectByType: {
    path: serverUrl + apiVersion + '/role/getRolesSelectByType',
    method: 'GET',
  },
  currentUserRoleType: {
    path: serverUrl + apiVersion + '/role/type/list',
    method: 'GET',
  },
  resourceSave: {
    path: serverUrl + apiVersion + '/role/{id}/resource/save',
    method: 'POST',
  },
  checkAndId: {
    path: serverUrl + apiVersion + '/role/check/{id}/{role}',
    method: 'GET',
  },
  check: {
    path: serverUrl + apiVersion + '/role/check/{role}',
    method: 'GET',
  },
}

export default role
