/**
 * 权限认证指令
 * @type {{}}
 */

const authDirective = {
  inserted (el, binding, vnode) {
    const data = vnode.context.$store.getters.authority
    if (data.indexOf(binding.value) === -1) {
      const _parentElement = el.parentNode
      if (_parentElement) {
        _parentElement.removeChild(el)
      }
    }
  }
}

export default authDirective
