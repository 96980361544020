import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import dict from './modules/dict'
import org from './modules/org'
import common from './modules/common'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    org,
    dict,
    common
  },
  state: {},
  mutations: {},
  actions: {},
  getters
})
