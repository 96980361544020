/*装备申请操作权限*/
export const APPLY_ADD = 'apply:add'
export const APPLY_ADD_Ju = 'apply:addJu'
export const APPLY_EDIT = 'apply:edit'
export const APPLY_DEL = 'apply:del'
export const APPLY_VIEW = 'apply:view'
export const APPLY_SEARCH = 'apply:search'
export const APPLY_CHECKING = 'apply:checking'
export const APPLY_REVOKE = 'apply:revoke'
export const APPLY_SAVE = 'apply:save'
export const APPLY_DOWNLOAD = 'apply:download'
export const APPLY_SUBMIT = 'apply:submit'
export const APPLY_SUPERVISE = 'apply:supervise'
export const APPLY_CHECKSUCCESS = 'apply:checksuccess'
export const APPLY_REJECT = 'apply:reject'
export const APPLY_COPY = 'apply:copy'

/*装备入库操作权限*/
export const STORAGE_ADD = 'storage:add'
export const STORAGE_ADD_JU = 'storage:addJu'
export const STORAGE_EDIT = 'storage:edit'
export const STORAGE_SEARCH = 'storage:search'
export const STORAGE_CHECKING = 'storage:checking'
export const STORAGE_VIEW = 'storage:view'
export const STORAGE_DEL = 'storage:del'
export const STORAGE_CHECKSUCCESS = 'storage:checksuccess'
export const STORAGE_CONFIRM = 'storage:storageconfirm'
export const STORAGE_REVOKE = 'storage:revoke'
export const STORAGE_SUBMIT = 'storage:submit'
export const STORAGE_SAVE = 'storage:save'
export const STORAGE_DOWNLOAD = 'storage:download'
export const STORAGE_COPY = 'storage:copy'
export const STORAGE_SUPERVISE = 'storage:supervise'
export const STORAGE_REJECT = 'storage:reject'

/*装备划拨操作权限*/
export const allot_CHECKING = 'allot:checking'
export const ALLOT_ADD = 'allot:add'
export const ALLOT_EDIT = 'allot:edit'
export const ALLOT_DEL = 'allot:del'
export const ALLOT_VIEW = 'allot:view'
export const ALLOT_SEARCH = 'allot:search'
export const ALLOT_CHECKSUCCESS = 'allot:checksuccess'
export const ALLOT_REVOKE = 'allot:revoke'
export const ALLOT_SUBMIT = 'allot:submit'
export const ALLOT_SAVE = 'allot:save'
export const ALLOT_DOWNLOAD = 'allot:download'
export const ALLOT_COPY = 'allot:copy'
export const ALLOT_SUPERVISE = 'allot:supervise'
export const ALLOT_REJECT = 'allot:reject'

/*物资领用操作权限*/
export const RECEIVE_ADD = 'receive:add'
export const RECEIVE_EDIT = 'receive:edit'
export const RECEIVE_DEL = 'receive:del'
export const RECEIVE_VIEW = 'receive:view'
export const RECEIVE_SEARCH = 'receive:search'
export const RECEIVE_SUBMIT = 'receive:submit'
export const RECEIVE_SAVE = 'receive:save'
export const RECEIVE_DOWNLOAD = 'receive:download'
export const RECEIVE_COPY = 'receive:copy'

/*物资借用操作权限*/
export const BORROW_ADD = 'borrow:add'
export const BORROW_EDIT = 'borrow:edit'
export const BORROW_VIEW = 'borrow:view'
export const BORROW_SEARCH = 'borrow:search'
export const BORROW_DOWLOAD = 'borrow:download'
export const BORROW_COPY = 'borrow:copy'
export const BORROW_SAVE = 'borrow:save'
export const BORROW_SUBMIT = 'borrow:submit'



/*维修保养操作权限*/
export const REPAIR_ADD = 'repair:add'
export const REPAIR_EDIT = 'repair:edit'
export const REPAIR_DEL = 'repair:del'
export const REPAIR_VIEW = 'repair:view'
export const REPAIR_SEARCH = 'repair:search'
export const REPAIR_DOWLOAD = 'repair:download'
export const REPAIR_COPY = 'repair:copy'
export const REPAIR_SUPERVISE = 'repair:supervise'
export const REPAIR_SAVE = 'repair:save'
export const REPAIR_REVOKE = 'repair:revoke'
export const REPAIR_SUBMIT = 'repair:submit'

/*报损报废操作权限*/
export const SCRAP_ADD = 'scrap:add'
export const SCRAP_EDIT = 'scrap:edit'
export const SCRAP_DEL = 'scrap:del'
export const SCRAP_VIEW = 'scrap:view'
export const SCRAP_SEARCH = 'scrap:search'
export const SCRAP_CHECKING = 'scrap:checking'
export const SCRAP_DOWLOAD = 'scrap:download'
export const SCRAP_COPY = 'scrap:copy'
export const SCRAP_SUPERVISE = 'scrap:supervise'
export const SCRAP_CHECKSUCCESS = 'scrap:checksuccess'
export const SCRAP_SAVE = 'scrap:save'
export const SCRAP_REJECT = 'scrap:reject'
export const SCRAP_REVOKE = 'scrap:revoke'
export const SCRAP_SUBMIT = 'scrap:submit'
export const SCRAP_WRITE_OFF = 'scrap:writeoff'

/*装备盘点操作权限*/
export const INVENTORY_ADD = 'inventory:add'
export const INVENTORY_EDIT = 'inventory:edit'
export const INVENTORY_DEL = 'inventory:del'
export const INVENTORY_VIEW = 'inventory:view'
export const INVENTORY_SEARCH = 'inventory:search'
export const INVENTORY_CHECKING = 'inventory:checking'
export const INVENTORY_DOWnLOAD = 'inventory:download'
export const INVENTORY_COPY = 'inventory:copy'
export const INVENTORY_SUPERVISE = 'inventory:supervise'
export const INVENTORY_CHECKSUCCESS = 'inventory:checksuccess'
export const INVENTORY_SAVE = 'inventory:save'
export const INVENTORY_REJECT = 'inventory:reject'
export const INVENTORY_REVOKE = 'inventory:revoke'
export const INVENTORY_SUBMIT = 'inventory:submit'