import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/csgz/dispatch/datav'
const dispatchDatavApi = () => {
  return {
    getKpiReportData: {
      path: serverUrl + apiVersion + base + '/get_kpi_report_data',
      method: 'GET'
    },
    getKpiReportData2: {
      path: serverUrl + apiVersion + '/csgz/report/record/statistics2',
      method: 'POST'
    },
    getCarStatus: {
      path: serverUrl + apiVersion + base + '/get_car_status',
      method: 'GET'
    },
    getKpiRecordData: {
      path: serverUrl + apiVersion + base + '/get_kpi_record_data',
      method: 'GET'
    },
    getCarStatistics: {
      path: serverUrl + apiVersion + base + '/get_car_statistics',
      method: 'GET'
    },
    getReportStatistics: {
      path: serverUrl + apiVersion + base + '/get_report_statistics',
      method: 'GET'
    },
    getReportRankData: {
      path: serverUrl + apiVersion + base + '/get_report_rank_data',
      method: 'GET'
    },
    getReportRankDataByCodes: {
      path: serverUrl + apiVersion + base + '/get_report_rank_data_by_codes',
      method: 'GET'
    },
    noPageSearchList: {
      path: serverUrl + apiVersion + base + '/find_all_report_record',
      method: 'GET'
    },
    noPageSearchListBYCodes: {
      path: serverUrl + apiVersion + base + '/find_all_report_record_by_codes',
      method: 'GET'
    },
    findLoopForecastListLimit: {
      path: serverUrl + apiVersion + base + '/find_loop_forecast_limit',
      method: 'GET'
    },
    findLoopForecastListLimitByCodes: {
      path: serverUrl + apiVersion + base + '/find_loop_forecast_limit_by_codes',
      method: 'GET'
    },
    getYearRecordStatistics: {
      path: serverUrl + apiVersion + base + '/get_year_record_statistics',
      method: 'GET'
    },
    getRecordStatistics: {
      path: serverUrl + apiVersion + base + '/get_record_statistics',
      method: 'GET'
    },
    archiveStatistics: {
      path: serverUrl + apiVersion + base + '/datav_archives_statistics',
      method: 'GET'
    },
    datavStatistics: {
      path: serverUrl + apiVersion + base + '/datav_statistics',
      method: 'GET'
    }
  }
}
export default dispatchDatavApi