import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/csgz/car/loop/report/record'
const carLoopReportRecordApi = () => {
  return {
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
  }
}
export default carLoopReportRecordApi