import { RouteView } from '../components/layouts'

const dutyRouter = {
  path: '/duty',
  component: RouteView,
  name: 'duty',
  meta: { title: '值班记录', props: true, keepAlive: false },
  children: [
    {
      path: '/duty/:listType',
      name: 'handover',
      component: () => import('../views/duty/DutyList'),
      props: true,
      meta: { title: '交班',   keepAlive: false, listType: 'handover' }
    },
    {
      path: '/duty/shift/:listType',
      name: 'handover',
      component: () => import('../views/duty/DutyList'),
      props: true,
      meta: { title: '接班',   keepAlive: false, listType: 'handover' }
    }
  ]
}
export default dutyRouter


