import store from '../../store'

/**
 * 权限认证
 * @param identity
 * @returns {*}
 */

const instance = function (identity) {
  return store.getters.authority.indexOf(identity) !== -1
}

function plugin (Vue) {
  if (plugin.installed) {
    return
  }
  Vue.prototype.$auth = instance
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(plugin)
}

export default plugin
