import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/xunjian/record/node'
const recordNodeApi = () => {
  return {
    count_times: {
      path: serverUrl + apiVersion + base + '/count_times',
      method: 'GET'
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    statistic_record_node_times: {
      path: serverUrl + apiVersion + base + '/statistic_record_node_times',
      method: 'GET'
    },
    statistic_abnormal_rank_list: {
      path: serverUrl + apiVersion + base + '/statistic_abnormal_rank_list',
      method: 'GET'
    },
    statistic_by_node_type: {
      path: serverUrl + apiVersion + base + '/statistic_by_node_type',
      method: 'GET'
    },
    screen_runing_state: {
      path: serverUrl + apiVersion + base + '/screen_runing_state',
      method: 'GET'
    }
  }
}
export default recordNodeApi