import Vue from 'vue'
import router from '../router'
import store from '../store'

// 加载进度条
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import SERVICE_URLS from '../api/service.url'
import { menuModify } from '../utils/util'

import message from 'ant-design-vue/es/message'

NProgress.configure({ showSpinner: true }) // NProgress Configuration

// 白名单
const whitelist = [
  '/cert/login',
  '/auth/login',
  '/exception/404',
  '/exception/403',
  '/exception/500'
]

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  if (whitelist.indexOf(to.path) === -1) {
    // 暂未加载登陆信息
    if (!store.getters.basicDataLoading) {
      Promise.all([Vue.http.get(SERVICE_URLS.auth.loginInfo.path)])
        .then((res) => {
          if (res[0].code === 0) {
            const menus = []
            if (res[0].body.column) {
              res[0].body.column.forEach((x) => {
                menus.push(menuModify(x))
              })
            }
            store.commit('SET_COLUMN_ITEMS', menus)
            store.commit('SET_CURRENT_USER', res[0].body.currentUser)
            store.commit('SET_AUTHORITY', res[0].body.auth)
            store.commit('SET_ROLES', res[0].body.roles)
            store.commit('SET_CURRENT_ORG', res[0].body.currentOrg)
            store.commit('SET_BASIC_DATA_LOADING', true)
            store.commit('SET_BUCKET_URL', res[0].body.bucketUrl)

            // 路由权限
            let canUrls = []
            res[0].body.column.some(function (item) {
              canUrls.push(item.urls)
              item.children.some(function (item2) {
                canUrls.push(item2.urls)
              })
            })
            if (canUrls.indexOf(to.path) === -1) {
              next({ path: '/exception/403' })
            } else {
              next()
            }
          } else {
            if (res[0].messageCode === 'no.login') {
              next({ path: '/cert/login' })
            } else {
              next({ path: '/exception/500' })
            }
          }
        })
        .catch((err) => {
          message.error(err)
          console.log(err)
          next({ path: '/exception/500' })
        })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((route) => {
  NProgress.done() // finish progress bar
})
