<template>
  <global-layout>
    <multi-tab v-if="$store.getters.multiTab"></multi-tab>
    <transition name="page-transition">
      <route-view></route-view>
    </transition>
  </global-layout>
</template>

<script>
  import RouteView from './RouteView'
  import MultiTab from '../MultiTab/MultiTab'
  import GlobalLayout from '../page/GlobalLayout'

  export default {
    name: 'BasicLayout',
    components: {
      RouteView,
      MultiTab,
      GlobalLayout
    },
    data () {
      return {}
    }
  }
</script>