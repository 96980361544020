import reportRecordApi from '@/api/csgz/reportrecord/api-report-record'
import gatherSpotApi from '@/api/csgz/gatherspot/api-gather-spot'
import carInfo from '@/api/csgz/car/api-car-info'
import carRecord from '@/api/csgz/car/api-car-record'
import carLoopRecord from '@/api/csgz/car/api-car-loop-record'
import carLoopReportRecordApi from '@/api/csgz/loop/api-loop-report-record'
import carConfig from '@/api/csgz/car/api-car-config'
import carMaintainApi from '@/api/csgz/car/api-car-maintain'
import carStatisticsApi from '@/api/csgz/car/api-car-statistics'
import carGisApi from '@/api/csgz/car/api-car-gis'
import repairRecordApi from '@/api/csgz/repair/api-repair-record'
import ticketInfoApi from '@/api/csgz/ticket/api-ticket-info'
import ticketProcessRecordApi from '@/api/csgz/ticket/api-ticket-process-record'
import sewagePlantApi from '@/api/csgz/sewageplant/api-sewage-plant'
import loopForecastApi from '@/api/csgz/loop/api-loop-forecast'
import areaDictionaryApi from '@/api/csgz/areadictionary/api-area-dictionary'
import dispatchAppApi from '@/api/csgz/reportrecord/api-dispatch-app'
import dispatchDatavApi from '@/api/csgz/datav/api-dispatch-datav'
import statisticsApi from '@/api/csgz/api-dispath-statistics'
import gatherSpotBindApi from './api-gather-spotBind'
import gatherSpotBindSosApi from './api-gather-spotBindSos'
import carInsuranceApi from '../csgz/car/api-car-insurance'
import phoneReportApi from './api-phone-report'

const csgz = {
  reportRecord: reportRecordApi(),
  gatherSpot: gatherSpotApi(),
  carInfoApi: carInfo(),
  carGisApi: carGisApi(),
  carRecordApi: carRecord(),
  carLoopRecordApi: carLoopRecord(),
  carLoopReportRecordApi: carLoopReportRecordApi(),
  carConfigApi: carConfig(),
  carMaintainApi: carMaintainApi(),
  carStatisticsApi: carStatisticsApi(),
  repairRecordApi: repairRecordApi(),
  ticketInfoApi: ticketInfoApi(),
  ticketProcessRecordApi: ticketProcessRecordApi(),
  sewagePlantApi: sewagePlantApi(),
  loopForecastApi: loopForecastApi(),
  areaDictionaryApi: areaDictionaryApi(),
  dispatchAppApi: dispatchAppApi(),
  dispatchDatavApi: dispatchDatavApi(),
  statisticsApi: statisticsApi(),
  gatherSpotBindApi: gatherSpotBindApi(),
  gatherSpotBindSosApi: gatherSpotBindSosApi(),
  carInsuranceApi: carInsuranceApi(),
  phoneReportApi: phoneReportApi()
}
export default csgz