const org = {
  state: {
    orgs: [],
    orgJobs: [],
    currentOrg: {},
    selfUnits: [],
    subUnits: [],
    allUnits: [],
    selfDepts: []
  },

  mutations: {
    SET_ORG_JOBS: (state, orgJobs) => {
      state.orgJobs = orgJobs
    },
    SET_SELF_UNITS: (state, selfUnits) => {
      state.selfUnits = selfUnits
    },
    SET_SUB_UNITS: (state, subUnits) => {
      state.subUnits = subUnits
    },
    SET_ALL_UNITS: (state, allUnits) => {
      state.allUnits = allUnits
    },
    SET_SELF_DEPTS: (state, selfDepts) => {
      state.selfDepts = selfDepts
    },
    SET_CURRENT_ORG: (state, currentOrg) => {
      state.currentOrg = currentOrg
    },
    SET_ORGS: (state, orgs) => {
      state.orgs = orgs
    }
  }
}

export default org
