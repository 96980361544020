import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/device/technology'
const technologyInfoApi = () => {
  return {
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    create: {
      path: serverUrl + apiVersion + base + '/create',
      method: 'POST'
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    delete: {
      path: serverUrl + apiVersion + base + '/{id}/del',
      method: 'DELETE',
      params: {
        id: 'Long'
      }
    },
    update: {
      path: serverUrl + apiVersion + base + '/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    select: {
      path: serverUrl + apiVersion + base + '/select',
      method: 'GET'
    },
    findMetricsByTechnologyId: {
      path: serverUrl + apiVersion + base + '/{technologyId}/find_metrics_by_technologyId',
      method: 'GET'
    },
    getTechnologyInfo: {
      path: serverUrl + apiVersion + base + '/get_technologyInfo',
      method: 'GET'
    },
    findMetricsByTechnologyCode: {
      path: serverUrl + apiVersion + base + '/{technologyCode}/find_metrics_by_technology_code',
      method: 'GET'
    },
    find_technologyInfo_by_point_id: {
      path: serverUrl + apiVersion + base + '/find_technologyInfo_by_point_id/{pointId}',
      method: 'GET',
      params: {
        pointId: 'Long'
      }
    },
    find_technologyInfo_by_key: {
      path: serverUrl + apiVersion + base + '/find_technologyInfo_by_key',
      method: 'GET'
    }
  }
}
export default technologyInfoApi