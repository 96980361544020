import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1/logs'

export default {
  search: {
    path: serverUrl + apiVersion + '/search',
    method: 'GET'
  },
  logDetail: {
    path: serverUrl + apiVersion + '/{id}/view',
    method: 'GET'
  },
  logsClear: {
    path: serverUrl + apiVersion + '/clear',
    method: 'POST'
  }
}