import serverUrl from './service.info'

const apiVersion = '/api/v1'

const document = {
  qiniu: {
    upToken: {
      path: serverUrl + apiVersion + '/qiniu/upToken',
      method: 'GET',
    },
  },
  search: {
    path: serverUrl + apiVersion + '/web/document/search',
    method: 'GET',
  },
  upload: {
    path: serverUrl + apiVersion + '/web/document/upload',
    method: 'POST',
  },
  froalaUpload: {
    path: serverUrl + apiVersion + '/web/document/froala_upload',
    method: 'POST',
  },
  download: {
    path: serverUrl + apiVersion + '/web/document/download/{id}',
    method: 'GET',
    params: {
      id: 'Long',
    },
  },
  downloadWithPath: {
    path: serverUrl + apiVersion + '/web/document/download',
    method: 'GET',
  },
  create: {
    path: serverUrl + apiVersion + '/web/document/create',
    method: 'POST',
  },
  moduleUpload: {
    path: serverUrl + apiVersion + '/web/document/module/upload',
    method: 'POST',
  },
  bidUpload: {
    path: serverUrl + apiVersion + '/web/document/module/upload/bid',
    method: 'POST',
  },
  moduleList: {
    path: serverUrl + apiVersion + '/web/document/module/list',
    method: 'GET',
  },
}

export default document
