import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/device/monitor'
const monitorApi = () => {
  return {
    statistics: {
      path: serverUrl + apiVersion + base + '/statistics',
      method: 'GET'
    },
    search: {
      path: serverUrl + apiVersion + base + '/searchMetrics',
      method: 'GET',
      params: {
        categoryId: 'Long'
      }
    },
    moreMetrics: {
      path: serverUrl + apiVersion + base + '/{deviceId}/moreMetrics',
      method: 'GET',
      params: {
        deviceId: 'Long'
      }
    },
    metricsHistory: {
      path: serverUrl + apiVersion + base + '/metricsHistory',
      method: 'GET'
    },
    categoryList: {
      path: serverUrl + apiVersion + base + '/categoryList',
      method: 'GET'
    },
    facilityList: {
      path: serverUrl + apiVersion + base + '/facilityList',
      method: 'GET'
    },
    metrics_history_list: {
      path: serverUrl + apiVersion + base + '/metrics_history_list',
      method: 'GET'
    }
  }
}
export default monitorApi