/* eslint-disable */
// const devUrl = 'http://127.0.0.1:9903'
const productUrl = 'http://xqqyapi.nxhbjt.cn'
// const productUrl = 'http://znqyapi.nxhbjt.cn'
const testUrl = 'http://127.0.0.1:9906'

let serverUrl

if (process.env.NODE_ENV === 'development') {
  serverUrl = devUrl
} else if (process.env.NODE_ENV === 'production') {
  serverUrl = productUrl
} else if (process.env.NODE_ENV === 'testing') {
  serverUrl = testUrl
}

export default serverUrl
