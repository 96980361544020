import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/csgz/car/maintain'

const carMaintainApi = () => {
  return {
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    create: {
      path: serverUrl + apiVersion + base + '/create',
      method: 'POST'
    },
    update: {
      path: serverUrl + apiVersion + base + '/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/detail',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    delete: {
      path: serverUrl + apiVersion + base + '/{id}/del',
      method: 'DELETE',
      params: {
        id: 'Long'
      }
    },
    export_excel: {
      path: serverUrl + apiVersion + base + '/export_excel',
      method: 'GET'
    },
  }
}
export default carMaintainApi