import { RouteView } from '../components/layouts'

const blogRouterConfig = {
  path: '/blog',
  component: RouteView,
  redirect: '/blog/list',
  name: 'blog',
  meta: { title: '内容管理', keepAlive: false },
  children: [
    {
      path: '/blog/list/list',
      name: 'blogList',
      component: () => import('../views/blog/list/List'),
      meta: { title: '所有文章', keepAlive: false }
    },
    {
      path: '/blog/list/detail',
      name: 'blogDetail',
      component: () => import('../views/blog/list/Detail'),
      meta: { title: '文章详情', keepAlive: false }
    },
    {
      path: '/blog/release/release',
      name: 'blogRelease',
      component: () => import('../views/blog/release/Release'),
      meta: { title: '文章发布', keepAlive: false }
    },
    {
      path: '/blog/tag/tag',
      name: 'blogTag',
      component: () => import('../views/blog/tag/Tag'),
      meta: { title: '文章标签', keepAlive: false }
    },
    {
      path: '/blog/type/type',
      name: 'blogType',
      component: () => import('../views/blog/type/Type'),
      meta: { title: '文章类型', keepAlive: true }
    }
  ]
}

export default blogRouterConfig