import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/csgz/car/loop/record'

const carLoopRecordApi = () => {
  return {
    select: {
      path: serverUrl + apiVersion + base + '/select',
      method: 'GET'
    },
    setDefault: {
      path: serverUrl + apiVersion + base + '/set_default',
      method: 'GET'
    },
    setModifyMileage: {
      path: serverUrl + apiVersion + base + '/set_modify_mileage',
      method: 'GET'
    },
    statistics: {
      path: serverUrl + apiVersion + base + '/statistics',
      method: 'GET'
    },
    search: {
      // path: serverUrl + apiVersion + base + '/search',

      // method: 'GET'
      path: serverUrl + apiVersion + base + '/searchByPage',
      method: 'GET'
    },
    create: {
      path: serverUrl + apiVersion + base + '/create',
      method: 'POST'
    },
    mapList: {
      path: serverUrl + apiVersion + base + '/map_list',
      method: 'GET'
    },
    synchronousDevices: {
      path: serverUrl + apiVersion + base + '/synchronous_devices',
      method: 'GET'
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    viewAll: {
      path: serverUrl + apiVersion + base + '/{id}/view_all',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    viewDetailAndImage: {
      path: serverUrl + apiVersion + base + '/{id}/view_images',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    update: {
      path: serverUrl + apiVersion + base + '/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    updateImages: {
      path: serverUrl + apiVersion + base + '/edit_images',
      method: 'POST',
    },
    delete: {
      path: serverUrl + apiVersion + base + '/{id}/del',
      method: 'DELETE',
      params: {
        id: 'Long'
      }
    },
    count_cars: {
      path: serverUrl + apiVersion + base + '/count_cars',
      method: 'GET'
    },
    select_driver_by_charge: {
      path: serverUrl + apiVersion + base + '/select_driver_by_charge',
      method: 'GET'
    },
    select_car_by_driver: {
      path: serverUrl + apiVersion + base + '/select_car_by_driver',
      method: 'GET'
    },
    export_word: {
      path: serverUrl + apiVersion + base + '/exportWord',
      method: 'GET'
    },
    exportExcel: {
      path: serverUrl + apiVersion + base + '/export_excel',
      method: 'GET'
    },
    exportImages: {
      path: serverUrl + apiVersion + base + '/export_images',
      method: 'GET'
    }
  }
}
export default carLoopRecordApi