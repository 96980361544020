import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/inspect/statistics'
const xjStatisticsApi = () => {
  return {
    // 按月份统计巡检次数
    count_inspect_num_with_month: {
      path: serverUrl + apiVersion + base + '/count_inspect_num_with_month',
      method: 'GET'
    },
    // 按人员统计巡检次数
    count_inspect_num_with_user: {
      path: serverUrl + apiVersion + base + '/count_inspect_num_with_user',
      method: 'GET'
    },
    // 按月份统计巡检公里及时长
    count_inspect_mileage_and_time_with_month: {
      path: serverUrl + apiVersion + base + '/count_inspect_mileage_and_time_with_month',
      method: 'GET'
    },
    // 按人员统计巡检公里及时长
    count_inspect_mileage_and_time_with_user: {
      path: serverUrl + apiVersion + base + '/count_inspect_mileage_and_time_with_user',
      method: 'GET'
    },
    // 按月份统计巡检公里及时长列表
    count_inspect_data_with_month: {
      path: serverUrl + apiVersion + base + '/count_inspect_data_with_month',
      method: 'GET'
    },
    // 按人员统计巡检公里及时长列表
    count_inspect_data_with_user: {
      path: serverUrl + apiVersion + base + '/count_inspect_data_with_user',
      method: 'GET'
    }

  }
}
export default xjStatisticsApi