import serverUrl from '../service.info'

const apiVersion = '/api/v1/amap/relation'

const relation = {
  search: {
    path: serverUrl + apiVersion + '/search',
    method: 'GET',
  },
  view: {
    path: serverUrl + apiVersion + '/{id}/view',
    method: 'GET',
  },
  create: {
    path: serverUrl + apiVersion + '/create',
    method: 'POST',
  },
  update: {
    path: serverUrl + apiVersion + '/{id}/update',
    method: 'PUT',
  },
  delete: {
    path: serverUrl + apiVersion + '/{id}/del',
    method: 'DELETE',
  },
  create_more: {
    path: serverUrl + apiVersion + '/create_more',
    method: 'POST',
  },
  list: {
    path: serverUrl + apiVersion + '/{mapId}/list',
    method: 'GET',
  },
}

export default relation
