import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/xunjian/user_real_info'
const userRealInfoApi = () => {
  return {
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    searchLocation: {
      path: serverUrl + apiVersion + base + '/search_location/{mapInfoId}',
      method: 'GET'
    }
  }
}
export default userRealInfoApi