import { RouteView } from '../components/layouts'

const settingRouterConfig = {
  path: '/settings',
  component: RouteView,
  redirect: '/settings/system/base',
  name: 'account',
  meta: { title: '设置', keepAlive: true },
  children: [
    {
      path: '/settings/system',
      name: 'settings',
      component: () => import('../views/system/Index'),
      meta: { title: '系统设置', keepAlive: true },
      redirect: '/settings/system/base',
      children: [
        {
          path: 'jvm',
          name: 'jvmSystemSetting',
          component: () => import('../views/system/other/JVM'),
          meta: { title: 'JVM状态', keepAlive: true }
        },
        {
          path: 'params',
          name: 'paramsSystemSetting',
          component: () => import('../views/system/params/Params'),
          meta: { title: '运行参数', keepAlive: true }
        },
        {
          path: 'dictionary',
          name: 'dictionarySystemSetting',
          component: () => import('../views/system/dictionary/Dictionary'),
          meta: { title: '字典配置', keepAlive: true }
        },
        {
          path: 'cache',
          name: 'cacheSystemSetting',
          component: () => import('../views/system/other/Cache'),
          meta: { title: '清理缓存', keepAlive: true }
        },
        {
          path: 'logs',
          name: 'logSystemSetting',
          component: () => import('../views/system/other/Logs'),
          meta: { title: '运行日志', keepAlive: true }
        }
      ]
    },
    {
      path: '/settings/system/resource',
      name: 'resourceSystemSetting',
      component: () => import('../views/system/resource/Resource'),
      meta: { title: '菜单管理', keepAlive: true }
    },
    {
      path: '/settings/system/roles',
      name: 'rolesSystemSetting',
      component: () => import('../views/system/role/RoleList'),
      meta: { title: '角色管理', keepAlive: true }
    },
    {
      path: '/settings/system/organization',
      name: 'organization',
      component: () => import('../views/system/organization/Organization'),
      meta: { title: '部门管理', keepAlive: true }
    },
    {
      path: '/plat/users/:type',
      name: 'platUser',
      component: () => import('../views/system/user/UserList'),
      props: (route) => ({ type: route.params.type }),
      meta: { title: '用户管理', keepAlive: false }
    },
    {
      path: '/app/upgrade',
      name: 'appUpgrade',
      component: () => import('../views/system/upgrade/List'),
      meta: { title: '升级管理', keepAlive: false }
    },
    {
      path: '/plat/integral/record/list',
      name: 'platIntegralRecord',
      component: () => import('../views/plat/integral/List'),
      meta: { title: '积分管理', keepAlive: true }
    },
    {
      path: '/plat/message/list',
      name: 'platMessage',
      component: () => import('../views/plat/message/List'),
      meta: { title: '平台消息', keepAlive: true }
    },
    {
      path: '/plat/home/config/list',
      name: 'platMessage',
      component: () => import('../views/plat/homeConfig/List'),
      meta: { title: '首页配置', keepAlive: true }
    },
    {
      path: '/system/auth/list',
      name: 'systemAuthList',
      component: () => import('../views/system/auth/AuthList'),
      meta: { title: '角色分配', keepAlive: true }
    },
    {
      path: '/plat/job',
      name: 'platJob',
      component: () => import('../views/system/job/Job'),
      meta: { title: '职位管理', keepAlive: true }
    }
  ]
}

export default settingRouterConfig