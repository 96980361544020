import serverUrl from './service.info'

const apiVersion = '/api/v1/blog'

const article = {
  list: {
    delMore: {
      path: serverUrl + apiVersion + '/article/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
    search: {
      path: serverUrl + apiVersion + '/article/search',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/article/{id}/view',
      method: 'GET',
      params: {
        id: 'Long',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/article/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    top: {
      path: serverUrl + apiVersion + '/article/{id}/top',
      method: 'POST',
      params: {
        id: 'Long',
      },
    },
    cancel_top: {
      path: serverUrl + apiVersion + '/article/{id}/cancel_top',
      method: 'POST',
      params: {
        id: 'Long',
      },
    },
    update_tag: {
      path: serverUrl + apiVersion + '/article/{id}/update_tag',
      method: 'POST',
      params: {
        id: 'Long',
      },
    },
  },
  init: {
    path: serverUrl + apiVersion + '/user/init',
    method: 'GET',
  },
  //tag文章标签接口
  tag: {
    select: {
      path: serverUrl + apiVersion + '/tag/select',
      method: 'GET',
      params: {
        id: 'Long',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/tag/create',
      method: 'POST',
    },
    search: {
      path: serverUrl + apiVersion + '/tag/search',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/tag/{id}/view',
      method: 'GET',
      params: {
        id: 'Long',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/tag/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    delMore: {
      path: serverUrl + apiVersion + '/tag/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
    update: {
      path: serverUrl + apiVersion + '/tag/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long',
      },
    },
  },
  //release文章发布接口
  release: {
    create: {
      path: serverUrl + apiVersion + '/article/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/article/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long',
      },
    },
    release: {
      path: serverUrl + apiVersion + '/article/release',
      method: 'POST',
    },
  },
  //type文章类型接口
  type: {
    select: {
      path: serverUrl + apiVersion + '/type/select',
      method: 'GET',
      params: {
        id: 'Long',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/type/create',
      method: 'POST',
    },
    delete: {
      path: serverUrl + apiVersion + '/type/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    view: {
      path: serverUrl + apiVersion + '/type/{id}/view',
      method: 'GET',
      params: {
        id: 'Long',
      },
    },
    update: {
      path: serverUrl + apiVersion + '/type/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long',
      },
    },
    tree: {
      path: serverUrl + apiVersion + '/type/tree',
      method: 'GET',
    },
    moveNode: {
      path: serverUrl + apiVersion + '/type/move',
      method: 'GET',
    },
  },
  selectByPid: {
    path: serverUrl + apiVersion + '/article/selectByPid',
    method: 'GET',
    params: {
      id: 'Long',
    },
  },
  update: {
    path: serverUrl + apiVersion + '/article/{id}/update',
    method: 'PUT',
    params: {
      id: 'Long',
    },
  },
  delete: {
    path: serverUrl + apiVersion + '/article/{id}/del',
    method: 'DELETE',
    params: {
      id: 'String',
    },
  },
  sorter: {
    path: serverUrl + apiVersion + '/article/sorter',
    method: 'PUT',
  },
}

export default article
