const user = {
  state: {
    currentUser: {
      name: '',
      username: ''
    },
    expandUserInfo: {},
    roles: [],
    authority: [],
    columnItems: [],
    basicDataLoading: false,
    loginModalStatus: false,
    unitsAndDepts: [],
    globalLoading: false,
    bucketUrl: undefined
  },
  mutations: {
    SET_AUTHORITY: (state, authority) => {
      state.authority = authority
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_CURRENT_USER: (state, currentUser) => {
      state.currentUser = currentUser
    },
    SET_BASIC_DATA_LOADING: (state, basicDataLoading) => {
      state.basicDataLoading = basicDataLoading
    },
    SET_LOGIN_MODAL_STATUS: (state, loginModalStatus) => {
      state.loginModalStatus = loginModalStatus
    },
    SET_COLUMN_ITEMS: (state, columnItems) => {
      state.columnItems = columnItems
    },
    SET_EXPAND_USER_INFO: (state, expandUserInfo) => {
      state.expandUserInfo = expandUserInfo
    },
    CLEAR_LOGIN_INFO: (state) => {
      state.currentUser = {
        name: '',
        username: ''
      }
      state.expandUserInfo = {}
      state.roles = []
      state.authority = []
      state.columnItems = []
      state.basicDataLoading = false
      state.loginModalStatus = false
    },
    SET_UNIT_DEPT_LIST: (state, unitsAndDepts) => {
      state.unitsAndDepts = unitsAndDepts
    },
    SET_GLOBAL_LOADING: (state, globalLoading) => {
      state.globalLoading = globalLoading
    },
    SET_BUCKET_URL: (state, bucketUrl) => {
      state.bucketUrl = bucketUrl
    }
  }
}

export default user
