/*设备类型操作权限*/
export const TYPE_CREATE_AND_UPDATE = 'type:createAndUpdate'
export const TYPE_DELETE = 'type:delete'
/*在线设备操作权限*/
export const CONFIG_VIEW = 'config:view'
export const CONFIG_CREATE_AND_UPDATE = 'config:createAndUpdate'
export const CONFIG_DELETE = 'config:delete'
export const ONLINE_RK = 'online:RK'
export const ONLINE_ZY = 'online:ZY'
export const IMAGE_CREATE = 'image:create'
/*库存设备操作权限*/
export const INVENTORY_CREATE_AND_UPDATE = 'inventory:createAndUpdate'
export const INVENTORY_DELETE = 'inventory:delete'
export const INVENTORY_DB = 'inventory:DB'
export const INVENTORY_CK = 'inventory:CK'
/*仓库基本信息操作*/
export const DEPOT_CREATE_AND_UPDATE = 'depot:createAndUpdate'
export const DEPOT_DELETE = 'depot:delete'
/*设施基本信息操作*/
export const FACILITY_CREATE_AND_UPDATE = 'facility:createAndUpdate'
export const FACILITY_DELETE = 'facility:delete'
/*网管基本信息操作*/
export const GATEWAY_CREATE_AND_UPDATE = 'gateway:createAndUpdate'
export const GATEWAY_DELETE = 'gateway:delete'
/*工艺基本信息操作*/
export const TECHNOLOGY_ADD_UPDATE = 'technology:createAndUpdate'
export const TECHNOLOGY_DELETE = 'technology:delete'
/*工艺挂点基本信息操作*/
export const MONITORING_POINT_ADD_UPDATE = 'monitoringPoint:createAndUpdate'
export const MONITORING_POINT_DELETE = 'monitoringPoint:delete'
/*交接班基本操作*/
export const DUTY_ADD_UPDATE = 'duty:createAndUpdate'
export const DUTY_DELETE = 'duty:delete'
export const DUTY_HANDOVER = 'duty:handover'
