<template>
  <a-locale-provider :locale="locale">
    <div id="app" @click="clickPage">
      <a-spin :delay="500" style="height: 100%"
              wrapperClassName="fs-app-spin"
              :spinning="globalLoading">
        <router-view/>
      </a-spin>
      <a-modal title="重新登录"
               :footer="null"
               width="420px"
               :closable="false"
               :visible="loginModalStatus">
        <login-form type="reLogin"></login-form>
      </a-modal>
    </div>
  </a-locale-provider>
</template>

<script>
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
  import { deviceEnquire, DEVICE_TYPE } from '@/utils/device'
  import { WINDOW_XY_CHANGE } from './store/mutation-types'
  import { mapState } from 'vuex'
  import LoginForm from './views/login/LoginForm'

  export default {
    data () {
      return {
        locale: zhCN,
        lastClickPageTime: '',
        // 30分钟自动超时
        timeoutTime: 30 * 60 * 1000,
        loginPath: '/cert/login'
      }
    },
    computed: {
      ...mapState(
        {
          globalLoading: state => state.user.globalLoading,
          loginModalStatus: state => state.user.loginModalStatus
        }
      )
    },
    components: {
      LoginForm
    },
    mounted () {
      const { $store } = this
      deviceEnquire(deviceType => {
        switch (deviceType) {
          case DEVICE_TYPE.DESKTOP:
            $store.commit('TOGGLE_DEVICE', 'desktop')
            $store.dispatch('setSidebar', true)
            break
          case DEVICE_TYPE.TABLET:
            $store.commit('TOGGLE_DEVICE', 'tablet')
            $store.dispatch('setSidebar', false)
            break
          case DEVICE_TYPE.MOBILE:
          default:
            $store.commit('TOGGLE_DEVICE', 'mobile')
            $store.dispatch('setSidebar', true)
            break
        }
      })
      // document.addEventListener('visibilitychange', function () {
      //   console.log(document.visibilityState)
      // })
      window.onresize = () => {
        this.$store.commit(WINDOW_XY_CHANGE)
      }
      this.$store.commit(WINDOW_XY_CHANGE)
    },
    created() {
      this.lastClickPageTime = new Date().getTime()
    },
    methods: {
      clickPage() {
        const now = new Date().getTime()
        const time = now - this.lastClickPageTime
        if (time > this.timeoutTime) {
          if (this.$route.path !== this.loginPath) {
            this.$router.push(this.loginPath)
          }
        } else {
          this.lastClickPageTime = now
        }
      }
    }
  }
</script>
<style>
  #app {
    height: 100%;
  }
</style>
