import { RouteView } from '../components/layouts'

const userRouterConfig = {
  path: '/user',
  component: RouteView,
  redirect: '/user/workplace',
  name: 'user',
  meta: { title: '用户中心', keepAlive: true },
  children: [
    {
      path: '/user/analysis',
      name: 'analysis',
      component: () => import('../views/dashboard/Analysis'),
      meta: { title: '数据面板', keepAlive: true }
    },
    {
      path: '/user/workplace',
      name: 'workplace',
      component: () => import('../views/dashboard/Workplace'),
      meta: { title: '工作台', keepAlive: true }
    },
    {
      path: '/user/workbench',
      name: 'workbench',
      component: () => import('../views/workbench/Home'),
      meta: { title: '调度工作台', keepAlive: true }
    },
    {
      path: '/user/setting',
      name: 'userSetting',
      component: () => import('../views/account/Index'),
      meta: { title: '个人设置', keepAlive: true },
      redirect: '/user/info',
      children: [
        {
          path: '/user/info',
          name: 'userInfo',
          component: () => import('../views/account/BaseSetting')
          // meta: { title: '基础信息', keepAlive: true }
        },
        {
          path: '/user/security',
          name: 'userSecurity',
          component: () => import('../views/account/Security')
          // meta: { title: '安全设置', keepAlive: true }
        },
        {
          path: '/user/custom',
          name: 'userCustom',
          component: () => import('../views/account/Custom')
          // meta: { title: '个性化设置', keepAlive: true }
        },
        {
          path: '/user/password',
          name: 'userPassword',
          component: () => import('../views/account/Password')
          // meta: { title: '修改密码', keepAlive: true }
        }
      ]
    }
  ]
}

export default userRouterConfig