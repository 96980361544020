import serverUrl from './service.info'

const apiVersion = '/api/v1/base'

const platBase = {
  check: {
    list: {
      path: serverUrl + apiVersion + '/check/list',
      method: 'GET',
    },
    check: {
      path: serverUrl + apiVersion + '/check/check',
      method: 'PUT',
    },
  },
  notice: {
    list: {
      path: serverUrl + apiVersion + '/check/list',
      method: 'GET',
    },
    search: {
      path: serverUrl + apiVersion + '/notice/search',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/notice/{id}/view',
      method: 'GET',
      params: {
        id: 'String',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/notice/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/notice/{id}/update',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    approval: {
      path: serverUrl + apiVersion + '/notice/{id}/{type}/approval',
      method: 'PUT',
      params: {
        id: 'String',
        type: 'String',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/notice/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    delMore: {
      path: serverUrl + apiVersion + '/notice/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
  },
  noticeState: {
    search: {
      path: serverUrl + apiVersion + '/notice/state/search',
      method: 'GET',
    },
    list: {
      path: serverUrl + apiVersion + '/notice/state/list',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/notice/state/{id}/view',
      method: 'GET',
      params: {
        id: 'String',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/notice/state/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/notice/state/{id}/update',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/notice/state/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    deleteMore: {
      path: serverUrl + apiVersion + '/notice/state/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
  },
  supervise: {
    search: {
      path: serverUrl + apiVersion + '/supervise/search',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/supervise/{id}/view',
      method: 'GET',
      params: {
        id: 'String',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/supervise/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/supervise/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/supervise/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    deleteMore: {
      path: serverUrl + apiVersion + '/supervise/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
  },
  superviseState: {
    search: {
      path: serverUrl + apiVersion + '/supervise/state/search',
      method: 'GET',
    },
    list: {
      path: serverUrl + apiVersion + '/supervise/state/list',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/supervise/state/{id}/view',
      method: 'GET',
      params: {
        id: 'String',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/supervise/state/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/supervise/state/{id}/update',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/supervise/state/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    delMore: {
      path: serverUrl + apiVersion + '/supervise/state/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
  },
  needDeal: {
    search: {
      path: serverUrl + apiVersion + '/deal/search',
      method: 'GET',
    },
    read: {
      path: serverUrl + apiVersion + '/deal/list/read',
      method: 'GET',
    },
    state: {
      path: serverUrl + apiVersion + '/deal/list/state',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/deal/{id}/view',
      method: 'GET',
      params: {
        id: 'String',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/deal/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/deal/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/deal/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    deleteMore: {
      path: serverUrl + apiVersion + '/deal/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
  },
  operationRecord: {
    search: {
      path: serverUrl + apiVersion + '/operation/record/search',
      method: 'GET',
    },
    list: {
      path: serverUrl + apiVersion + '/operation/record/list',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/operation/record/{id}/view',
      method: 'GET',
      params: {
        id: 'String',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/operation/record/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/operation/record/{id}/update',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/operation/record/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    deleteMore: {
      path: serverUrl + apiVersion + '/operation/record/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
  },
  platMessage: {
    search: {
      path: serverUrl + apiVersion + '/plat/message/search',
      method: 'GET',
    },
    list: {
      path: serverUrl + apiVersion + '/plat/message/list',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/plat/message/{id}/view',
      method: 'GET',
      params: {
        id: 'String',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/plat/message/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/plat/message/{id}/update',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/plat/message/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    deleteMore: {
      path: serverUrl + apiVersion + '/plat/message/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
  },
  integral: {
    homeIntegral: {
      path: serverUrl + apiVersion + '/integral/unit',
      method: 'GET',
    },
    search: {
      path: serverUrl + apiVersion + '/integral/search',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/integral/{id}/view',
      method: 'GET',
      params: {
        id: 'String',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/integral/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/integral/{id}/update',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/integral/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    delMore: {
      path: serverUrl + apiVersion + '/integral/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
  },
  supplier: {
    search: {
      path: serverUrl + apiVersion + '/supplier/search',
      method: 'GET',
    },
    select: {
      path: serverUrl + apiVersion + '/supplier/select',
      method: 'GET',
    },
    list: {
      path: serverUrl + apiVersion + '/supplier/list',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/supplier/{id}/view',
      method: 'GET',
      params: {
        id: 'String',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/supplier/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/supplier/{id}/update',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/supplier/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    deleteMore: {
      path: serverUrl + apiVersion + '/supplier/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
  },
  suggestion: {
    search: {
      path: serverUrl + apiVersion + '/suggestion/search',
      method: 'GET',
    },
    list: {
      path: serverUrl + apiVersion + '/suggestion/list',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/suggestion/{id}/view',
      method: 'GET',
      params: {
        id: 'String',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/suggestion/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/suggestion/{id}/update',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/suggestion/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    deleteMore: {
      path: serverUrl + apiVersion + '/suggestion/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
  },
  controller: {
    search: {
      path: serverUrl + '/api/v1/dpc/handle/exchangeRecord/countAllRecordType',
      method: 'GET',
    },
  },
}

export default platBase
