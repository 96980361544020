import { RouteView } from '../components/layouts'

const exampleRouterConfig = {
  path: '/example',
  component: RouteView,
  redirect: '/example/upload',
  name: 'example',
  meta: { title: '组件示例', keepAlive: false },
  children: [
    {
      path: 'upload',
      name: 'UploadExample',
      component: () => import('../views/other/example/UploadExample'),
      meta: { title: '上传示例' }
    },
    {
      path: 'textrich',
      name: 'RichTextExample',
      component: () => import('../views/other/example/TextRichExample'),
      meta: { title: '富文本示例' }
    }
  ]

}
export default exampleRouterConfig