import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/device/info'
const equipmentInfoApi = () => {
  return {
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    mapSearch: {
      path: serverUrl + apiVersion + base + '/map/search',
      method: 'GET'
    },
    selectDepots: {
      path: serverUrl + apiVersion + base + '/selectDepots',
      method: 'GET'
    },
    create: {
      path: serverUrl + apiVersion + base + '/create',
      method: 'POST'
    },
    detail: {
      path: serverUrl + apiVersion + base + '/{id}/detail',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    update: {
      path: serverUrl + apiVersion + base + '/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    delete: {
      path: serverUrl + apiVersion + base + '/{id}/del',
      method: 'DELETE',
      params: {
        id: 'Long'
      }
    },
    transfer: {
      path: serverUrl + apiVersion + base + '/{id}/transfer',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    areaTree: {
      path: serverUrl + apiVersion + base + '/areaTree',
      method: 'GET'
    },
    sceneImage: {
      path: serverUrl + apiVersion + base + '/{id}/sceneImage',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    findDepots: {
      path: serverUrl + apiVersion + base + '/{orgId}/findDepots',
      method: 'GET',
      params: {
        orgId: 'Long'
      }
    },
    statistics: {
      path: serverUrl + apiVersion + base + '/statistics',
      method: 'GET'
    },
    aggregationInfo: {
      path: serverUrl + apiVersion + base + '/aggregationInfo/{deviceId}',
      method: 'GET',
      params: {
        deviceId: 'Long'
      }
    },
    aggregationInfo2: {
      path: serverUrl + apiVersion + base + '/aggregationInfo2/{facilityId}',
      method: 'GET',
      params: {
        facilityId: 'Long'
      }
    },
    selectByFacilityId: {
      path: serverUrl + apiVersion + base + '/select_by_facilityId/{facilityId}',
      method: 'GET',
      params: {
        facilityId: 'Long'
      }
    },
    aggregationInfoByPoint: {
      path: serverUrl + apiVersion + base + '/aggregation_info_by_point_id/{pointId}',
      method: 'GET',
      params: {
        pointId: 'Long'
      }
    },
    planeCoordinateConvertor: {
      path: serverUrl + apiVersion  + '/amap/coordinate/planeCoordinate',
      method: 'GET'
    }
  }
}
export default equipmentInfoApi