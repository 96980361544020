import { RouteView } from '../../components/layouts'

const info = [
  {
    path: '/baseinfo/car',
    component: RouteView,
    redirect: '/baseinfo/car',
    name: 'info',
    meta: { title: '车辆管理', keepAlive: false },
    children: [
      {
        path: '/baseinfo/car',
        name: 'info',
        component: () => import('../../views/csgz/car/info/List'),
        meta: { title: '车辆基础信息', keepAlive: false }
      },
      {
        path: '/baseinfo/config',
        name: 'config',
        component: () => import('../../views/csgz/car/config/List'),
        meta: { title: '司机车辆配置', keepAlive: false }
      },
      {
        path: '/baseinfo/maintain',
        name: 'maintain',
        component: () => import('../../views/csgz/car/maintain/List'),
        meta: { title: '车辆维修保养', keepAlive: false }
      },
      {
        path: '/car/history/track',
        name: 'HistoryTrack',
        component: () => import('../../views/csgz/car/info/HistoryTrack'),
        meta: { title: '历史轨迹追踪', keepAlive: false, hideMargin: true, hideFooter: true },
      },
      {
        path: '/car/insurance',
        name: 'insurance',
        component: () => import('../../views/csgz/car/insurance/List'),
        meta: { title: '车辆保险', keepAlive: false }
      },
    ]
  }
]

export default info