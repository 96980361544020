/**
 * Created by yangyang on 2017/2/15.
 */
import axios from 'axios'
import { DEFAULT_X_AUTH_TOKEN, DEFAULT_REMEMBER_ME } from '../../store/mutation-types'
import VueCookie from 'vue-cookie'

const instance = axios.create()

instance.interceptors.request.use(config => {
  const rememberMe = VueCookie.get(DEFAULT_REMEMBER_ME)
  if (rememberMe) {
    config.headers[DEFAULT_REMEMBER_ME] = rememberMe
  }
  const xAuthToken = VueCookie.get(DEFAULT_X_AUTH_TOKEN)
  if (xAuthToken) {
    config.headers[DEFAULT_X_AUTH_TOKEN] = xAuthToken
  }
  return config
})

const rememberMeTimeOut = 604800000
const tokenTimeOut = 7200000

instance.interceptors.response.use(response => {
  const rememberMe = response.headers[DEFAULT_REMEMBER_ME]
  if (rememberMe) {
    if (rememberMe === 'deleteMe') {
      VueCookie.delete(DEFAULT_REMEMBER_ME)
    } else {
      let cookietime = new Date()
      cookietime.setTime(cookietime.getTime() + rememberMeTimeOut)
      VueCookie.set(DEFAULT_REMEMBER_ME, rememberMe, { expires: cookietime })
    }
  }
  const xAuthToken = response.headers[DEFAULT_X_AUTH_TOKEN]
  if (xAuthToken) {
    let expires = new Date()
    expires.setTime(expires.getTime() + tokenTimeOut)
    VueCookie.set(DEFAULT_X_AUTH_TOKEN, xAuthToken, { expires: expires })
  } else {
    VueCookie.delete(DEFAULT_X_AUTH_TOKEN)
  }
  return response.data
}, err => {
  return err
})

function plugin (Vue) {
  if (plugin.installed) {
    return
  }
  Vue.http = instance
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(plugin)
}

export default plugin
