import serverUrl from '../service.info'

const apiVersion = '/api/v1/oa/duty/note'
const dutyNoteApi = {
  search: {
    path: serverUrl + apiVersion + '/search',
    method: 'GET',
  },
  view: {
    path: serverUrl + apiVersion + '/{id}/view',
    method: 'GET',
  },
  create: {
    path: serverUrl + apiVersion + '/create',
    method: 'POST',
  },
  update: {
    path: serverUrl + apiVersion + '/{id}/update',
    method: 'PUT',
  },
  delete: {
    path: serverUrl + apiVersion + '/{id}/del',
    method: 'DELETE',
  },
}

export default dutyNoteApi
