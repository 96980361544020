<template>
  <div class="logo" :style="{'padding-left':(showTitle?20:20)+'px'}">
    <router-link :to="{name:'dashboard'}">
      <img v-if="layoutMode==='topmenu'" class="svg" src="../../../public/icon_yunying_blue.png"
           style="float: left;margin-top: 10px;width: 42px;height: 42px"/>
      <logo-svg v-if="!sidebarOpened" style="float: left;margin-top: 14px;" alt="logo"></logo-svg>
      <h1 style="float: left" v-if="layoutMode==='topmenu'">{{ title }}</h1>
      <div class="small-title" v-if="layoutMode==='sidemenu'&&sidebarOpened">{{title}}</div>
    </router-link>
  </div>
</template>

<script>
  import LogoSvg from '../../../public/icon_yylc.svg?inline'
  import { mixin, mixinDevice } from '../../utils/mixin.js'

  export default {
    name: 'Logo',
    mixins: [mixin, mixinDevice],
    components: {
      LogoSvg
    },
    props: {
      title: {
        type: String,
        default: '厕所改造调度运营系统',
        required: false
      },
      showTitle: {
        type: Boolean,
        default: true,
        required: false
      }
    }
  }
</script>
<style scoped>

  .small-title {
    display: block;
    width: 200px;
    font-size: 20px;
    float: left;
    color: white;
    font-weight: bold;
    word-wrap: break-word;
  }
</style>
