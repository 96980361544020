import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/csgz/gather/spotBind'

const gatherSpotBindApi = () => {
  return {
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    bind: {
      path: serverUrl + apiVersion + base + '/bind',
      method: 'POST'
    },
    history_chart_detail: {
      path: serverUrl + apiVersion + base + '/unbind/{spotId}',
      method: 'GET',
    },
  }
}
export default gatherSpotBindApi