import { RouteView } from '../../components/layouts'

const hkCameraManage = [
  {
    path: '/hkcamera',
    component: RouteView,
    redirect: '/hkcamera',
    name: 'hkcamera',
    meta: { title: '视频监控', keepAlive: false },
    children: [
      {
        path: '/hkcamera/List',
        name: 'hkcameraInfo',
        component: () => import('../../views/hkcamera/List'),
        meta: { title: '视频监控管理', keepAlive: false }
      }
    ]
  }
]

export default hkCameraManage