import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/device/warning'
const warningApi = () => {
  return {
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long',
      },
    },
    search_for_map: {
      path: serverUrl + apiVersion + base + '/search_for_map',
      method: 'GET',
    },
    deal: {
      path: serverUrl + apiVersion + base + '/deal',
      method: 'POST',
    },
  }
}
export default warningApi
