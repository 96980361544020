/*系统权限*/

//应用管理 角色管理、应用授权
export const SYSTEM_AUTH_AND_ROLE = 'System:authAndRole'

//用户查询
export const USER_VIEW = 'user:view'

//用户增改
export const USER_CREATE_AND_UPDATE = 'user:createAndUpdate'

//用户删除
export const USER_DELETE = 'user:delete'

//密码重置
export const USER_RESET_PASSWORD = 'user:resetPassword'

//资源管理
export const RESOURCES_MANAGER = 'resources:manager'

//部门管理
export const ORGANIZATION_MANAGER = 'organization:manager'

//系统参数配置
export const SYSTEM_PARAMS_MANAGER = 'systemParams:manager'

//系统缓存管理
export const SYSTEM_CACHE_MANAGER = 'systemCache:manager'

//根目录文件夹创建
export const FOLDER_CREATE = 'folder:create'

//文件夹管理
export const FILE_DIR_OPTION = 'fileDir:option'

//文件上传
export const FILE_UPLOAD = 'file:upload'