import { RouteView } from '../../components/layouts'

const  monitoring = [
  {
    path: '/equipment2/monitoring',
    component: RouteView,
    redirect: '/equipment2/monitoring',
    name: 'monitoring',
    meta: { title: '设备监测', keepAlive: false },
    children: [
      {
        path: '/equipment2/monitoring',
        name: 'monitoringHome',
        component: () => import('../../views/equipment2/monitoring/List'),
        meta: { title: '设备监测列表', keepAlive: false }
      }
    ]
  }
]

export default monitoring