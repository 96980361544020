import serverUrl from '../service.info'

const apiVersion = '/api/v1/amap/overlay'

const overlay = {
  search: {
    path: serverUrl + apiVersion + '/search',
    method: 'GET',
  },
  searchAll: {
    path: serverUrl + apiVersion + '/search_all',
    method: 'POST',
  },
  view: {
    path: serverUrl + apiVersion + '/{id}/view',
    method: 'GET',
  },
  viewByInstance: {
    path: serverUrl + apiVersion + '/view_by_instance',
    method: 'GET',
  },
  create: {
    path: serverUrl + apiVersion + '/create',
    method: 'POST',
  },
  update: {
    path: serverUrl + apiVersion + '/{id}/update',
    method: 'PUT',
  },
  delete: {
    path: serverUrl + apiVersion + '/{id}/del',
    method: 'DELETE',
  },
}

export default overlay
