import { RouteView } from '../components/layouts'

const csgzStatisticsRouter = {
  path: '/statistics',
  component: RouteView,
  redirect: '/statistics/loop/day',
  name: 'statistics',
  meta: { title: '统计分析', keepAlive: false },
  children: [
    {
      path: '/statistics/loop/day',
      name: 'day',
      component: () => import('../views/csgz/statistics/loopday/DayCard'),
      meta: { title: '按日统计分析', keepAlive: false }
    },
    {
      path: '/statistics/loop/history',
      name: 'history',
      component: () => import('../views/csgz/statistics/loophistory/History'),
      meta: { title: '历史统计分析', keepAlive: false }
    },
    {
      path: '/statistics/loop/historyByArea',
      name: 'history',
      component: () => import('../views/csgz/statistics/loophistory/HistoryByArea'),
      meta: { title: '历史统计分析', keepAlive: false }
    },
    {
      path: '/statistics/report/record/month',
      name: 'month',
      component: () => import('../views/csgz/statistics/reportrecord/ReportRecordMonth'),
      meta: { title: '任务登记统计', keepAlive: false }
    },
    {
      path: '/statistics/loophistory/dateAndArea',
      name: 'dateAndArea',
      component: () => import('../views/csgz/statistics/loophistory/HistoryByDateAndArea'),
      meta: { title: '按区域统计分析', keepAlive: false }
    },
  ]
}

export default csgzStatisticsRouter