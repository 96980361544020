import { RouteView } from '../../components/layouts'

const technology = [
  {
    path: '/equipment2/technology',
    component: RouteView,
    redirect: '/equipment2/technology',
    name: 'monitoring',
    meta: { title: '工艺管理', keepAlive: false },
    children: [
      {
        path: '/equipment2/technology',
        name: 'technologyHome',
        component: () => import('../../views/equipment2/technology/List'),
        meta: { title: '工艺管理列表', keepAlive: false },
      },
      {
        path: '/equipment2/technologyPoint',
        name: 'technologyPointHome',
        component: () => import('../../views/equipment2/technologyPoint/List'),
        meta: { title: '工艺挂点管理列表', keepAlive: false },
      },
    ],
  },
]

export default technology
