import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/csgz/dispatch/statistics'

const statisticsApi = () => {
  return {
    dispatch_history_statistics: {
      path: serverUrl + apiVersion + base + '/dispatch_history_statistics',
      method: 'POST'
    },
    history_detail_statistics: {
      path: serverUrl + apiVersion + base + '/history_detail_statistics',
      method: 'POST'
    },
    history_chart_detail: {
      path: serverUrl + apiVersion + base + '/history_chart_detail',
      method: 'POST',
    },
  }
}
export default statisticsApi