/*车辆操作权限*/
export const INFO_CREATE_AND_UPDATE = 'carInfo:createAndUpdate'
export const INFO_VIEW = 'carInfo:view'
export const INFO_DELETE = 'carInfo:delete'
export const INFO_SYNC = 'carInfo:sync'
/*行车记录操作权限*/
export const RECORD_CREATE_AND_UPDATE = 'carRecord:createAndUpdate'
export const RECORD_HISTORY = 'carRecord:history'
export const RECORD_VIEW = 'carRecord:view'
export const RECORD_DELETE = 'carRecord:delete'

