import { BasicLayout, RouteView } from '../components/layouts'
import settingsRouterConfig from './system.router'
import userRouterConfig from './user.router'
import exampleRouterConfig from './example.router'
import noticeRouterConfig from './notice.router'
import blogRouterConfig from './blog.router'
import equipment2Router from '@/router/equipment2/router-equipment'
import amapRouterConfig from '@/router/amap.router'
import dataVRouter from '@/router/datav.router'
import xjRouter from './xj.router'
import carRouter from '../router/car/router-car'
import hkCameraManage from '@/router/hkcamera/router-hkcamera'
import ticketRouter from './ticket.router'
import damagedreportRouter from './damagedreport.router'
import dutyNoteConfig from './dutyNote.router'
import dutyRouter from './duty.router'
import reportForms from './reportForms.router'

//农村厕所改造
import csgzBaseRouter from './csgzbase.router'
import csgzReportRecordRouter from './csgzreportreport.router'
import csgzPanelRouter from '@/router/csgzpanel.router'
import csgzRepairRouter from '@/router/csgzrepair.router'
import csgzStatisticsRouter from '@/router/csgzstatistics.router'
import invokeStatistic from '@/router/invoke.router'

const primaryRouterConfig = {
  path: '/',
  name: 'index',
  component: BasicLayout,
  meta: { title: '首页' },
  redirect: '/cert/login',
  children: [
    {
      path: '/dashboard',
      name: 'dashboard',
      redirect: '/dashboard/workplace',
      component: RouteView,
      meta: { title: '仪表盘', keepAlive: true },
      children: [
        {
          path: '/dashboard/workplace',
          name: 'Workplace',
          component: () => import('../views/dashboard/Workplace'),
          meta: { title: '工作台', keepAlive: true }
        }
      ]
    },
    {
      path: '/help',
      name: 'help',
      hidden: true,
      component: () => import('../views/other/Help'),
      meta: { title: '帮助文档', keepAlive: true }
    },
    settingsRouterConfig,
    userRouterConfig,
    noticeRouterConfig,
    exampleRouterConfig,
    blogRouterConfig,
    amapRouterConfig,
    dataVRouter,
    xjRouter,
    ...equipment2Router,
    ...carRouter,
    ...hkCameraManage,
    ticketRouter,
    damagedreportRouter,
    dutyRouter,
    dutyNoteConfig,
    reportForms,
    csgzReportRecordRouter,
    csgzBaseRouter,
    csgzPanelRouter,
    csgzRepairRouter,
    csgzStatisticsRouter,
    invokeStatistic
  ]
}
export default primaryRouterConfig