import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/monitoring/point/control'
const monitoringPointControlApi = () => {
  return {
    write: {
      path: serverUrl + apiVersion + base + '/write',
      method: 'GET'
    }
  }
}
export default monitoringPointControlApi