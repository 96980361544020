import serverUrl from './service.info'

const apiVersion = '/api/v1'

const resource = {
  tree: {
    path: serverUrl + apiVersion + '/resource/tree',
    method: 'GET',
    params: {
      rId: 'Long',
    },
  },
  view: {
    path: serverUrl + apiVersion + '/resource/{id}/view',
    method: 'GET',
    params: {
      id: 'Long',
    },
  },
  select: {
    path: serverUrl + apiVersion + '/resource/select',
    method: 'GET',
    params: {
      id: 'Long',
    },
  },
  delete: {
    path: serverUrl + apiVersion + '/resource/{id}/del',
    method: 'DELETE',
    params: {
      id: 'Long',
    },
  },
  update: {
    path: serverUrl + apiVersion + '/resource/{id}/update',
    method: 'PUT',
    params: {
      id: 'Long',
    },
  },
  create: {
    path: serverUrl + apiVersion + '/resource/create',
    method: 'POST',
  },
  auth: {
    path: serverUrl + apiVersion + '/resource/auth',
    method: 'GET',
  },
  moveNode: {
    path: serverUrl + apiVersion + '/resource/move',
    method: 'GET',
  },
}

export default resource
