<template>
  <!-- , width: fixedHeader ? `calc(100% - ${sidebarOpened ? 256 : 80}px)` : '100%'  -->
  <div class="header-animat">
    <a-layout-header
      v-if="!headerBarFixed"
      :class="[
        fixedHeader && 'ant-header-fixedHeader',
        sidebarOpened ? 'ant-header-side-opened' : 'ant-header-side-closed',
      ]"
      :style="{ padding: '0' }"
    >
      <div v-if="mode === 'sidemenu'" class="header">
        <a-icon
          v-if="device === 'mobile'"
          class="trigger"
          :type="collapsed ? 'menu-fold' : 'menu-unfold'"
          @click="toggle"
        ></a-icon>
        <a-icon v-else class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="toggle"></a-icon>

        <user-menu></user-menu>
      </div>
      <div v-else :class="['top-nav-header-index', theme]">
        <div class="header-index-wide">
          <div class="header-index-left">
            <logo class="top-nav-header" :show-title="device !== 'mobile'"></logo>
            <s-menu v-if="device !== 'mobile'" mode="horizontal" :menu="menus" :theme="theme"></s-menu>
            <a-icon v-else class="trigger" :type="collapsed ? 'menu-fold' : 'menu-unfold'" @click="toggle"></a-icon>
          </div>
          <user-menu class="header-index-right"></user-menu>
        </div>
      </div>
    </a-layout-header>
  </div>
</template>

<script>
import UserMenu from '../tools/UserMenu'
import SMenu from '../menu/'
import Logo from '../tools/Logo'

import { mixin } from '../../utils/mixin.js'

export default {
  name: 'GlobalHeader',
  components: {
    UserMenu,
    SMenu,
    Logo,
  },
  mixins: [mixin],
  props: {
    mode: {
      type: String,
      //sidemenu, topmenu
      default: 'sidemenu',
    },
    menus: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      required: false,
      default: 'dark',
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    device: {
      type: String,
      required: false,
      default: 'desktop',
    },
  },
  data() {
    return {
      headerBarFixed: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (this.autoHideHeader) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop > 100) {
          this.headerBarFixed = true
        } else {
          this.headerBarFixed = false
        }
      } else {
        this.headerBarFixed = false
      }
    },
    toggle() {
      this.$emit('toggle')
    },
  },
}
</script>
