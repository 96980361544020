const getters = {
  //主题
  device: state => state.app.device,
  theme: state => state.app.theme,
  color: state => state.app.color,
  multiTab: state => state.app.multiTab,
  //用户相关
  roles: state => state.user.roles,
  authority: state => state.user.authority,
  currentUser: state => state.user.currentUser,
  columnItems: state => state.user.columnItems,
  expandUserInfo: state => state.user.expandUserInfo,
  basicDataLoading: state => state.user.basicDataLoading,
  //字典数据
  jobs: state => state.dict.jobs,
  subjects: state => state.dict.subjects,
  //部门数据
  selfUnits: state => state.org.selfUnits,
  subUnits: state => state.org.subUnits,
  allUnits: state => state.org.allUnits,
  selfDepts: state => state.org.selfDepts,
  orgs: state => state.org.orgs,
  orgJobs: state => state.org.orgJobs,
  currentOrg: state => state.org.currentOrg,
  // 轮训执行通知消息
  intervalMessage: state => state.common.intervalMessage,
  // 集污点
  gatherSpots: state => state.common.gatherSpots,
}

export default getters
