import serverUrl from './service.info'

const apiVersion = '/api/v1'

const system = {
  search: {
    path: serverUrl + apiVersion + '/config/search',
    method: 'GET',
  },
  update: {
    path: serverUrl + apiVersion + '/config/{id}/update',
    method: 'PUT',
  },
  hibernateCache: {
    path: serverUrl + apiVersion + '/cache/level2/evict',
    method: 'POST',
  },
  shiroCache: {
    path: serverUrl + apiVersion + '/cache/shiro/auth/evict',
    method: 'POST',
  },
  info: {
    path: serverUrl + apiVersion + '/system/info',
    method: 'GET',
  },
  logs: {
    path: serverUrl + apiVersion + '/logs/search',
    method: 'GET',
  },
  logsClear: {
    path: serverUrl + apiVersion + '/logs/clear',
    method: 'POST',
  },
  logDetail: {
    path: serverUrl + apiVersion + '/logs/{id}/view',
    method: 'GET',
  },
  ocrDocument: {
    search: {
      path: serverUrl + apiVersion + '/ocr/document/search',
      method: 'GET',
    },
    list: {
      path: serverUrl + apiVersion + '/ocr/document/list',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/ocr/document/{id}/view',
      method: 'GET',
      params: {
        id: 'String',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/ocr/document/override/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/ocr/document/override/{id}/update',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/ocr/document/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    delMore: {
      path: serverUrl + apiVersion + '/ocr/document/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
    beginocr: {
      path: serverUrl + apiVersion + '/ocr/document/ocr/{type}/{id}',
      method: 'GET',
      params: {
        type: '',
        id: '',
      },
    },
  },
}

export default system
